<template>
  <CModal
    centered
    :closeOnBackdrop="false"
    size="xl"
    :title="$t('lk.videoEncrypt.EncryptModal.title') + (video.title || '')"
    :show="show"
    @update:show="close"
    class="EncryptModal"
  >
    <CInput
      :disabled="isSendToQueue || isLoading"
      v-model="hls_time"
      :label="$t('lk.videoEncrypt.EncryptModal.hls_time')"
      type="number"
      min="10"
      max="180"
    />
    <p>Статус:
      <span v-if="error" class="text-danger">{{ $t('lk.videoEncrypt.EncryptModal.error') }}</span>
      <span v-else-if="status">{{ $t(`lk.videoEncrypt.table.statuses['${status}']`) }}</span>
    </p>

    <div v-if="isSendToQueue">
      <div class="EncryptModal__log">
        <pre v-text="log" ref="log"></pre>
      </div>
      <pre class="alert alert-danger" v-if="error">{{error}}</pre>
      <div class="EncryptModal__btnBlock" v-if="showClose">
        <CButton class="shadow-none EncryptModal__btn"
                 color="primary"
                 :disabled="isLoading"
                 @click="close">
          {{$t('lk.videoEncrypt.EncryptModal.btnClose')}}
        </CButton>
      </div>
    </div>

    <div class="EncryptModal__btnBlock" v-if="!isSendToQueue">
      <CLoadingButtonCustom
        class="shadow-none EncryptModal__btn"
        color="success"
        :loading="isLoading"
        :disabled="isLoading || disabledForm"
        @click.native="submit"
      >
        {{$t('lk.videoEncrypt.EncryptModal.btnSubmit')}}
      </CLoadingButtonCustom>
      <CLoadingButtonCustom
        v-if="this.video.status === 'encrypted' || this.video.status === 'encrypting' || this.video.status === 'queue'"
        class="shadow-none EncryptModal__btn"
        color="light"
        :loading="isLoading"
        :disabled="isLoading"
        @click.native="showLog"
      >
        {{$t('lk.videoEncrypt.EncryptModal.btnStatus')}}
      </CLoadingButtonCustom>
      <CButton class="shadow-none EncryptModal__btn"
               color="primary"
               :disabled="isLoading"
               @click="close">
        {{$t('lk.videoEncrypt.EncryptModal.btnCancel')}}
      </CButton>
    </div>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EncryptModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoading: false,
      isSendToQueue: false,
      showClose: false,
      checkLogTo: null,
      processVideo: {},
      log: '',
      error: '',
      checkStatusIs0: false,
      hls_time: this.video.hls_time || 10
    }
  },
  watch: {
    'show': function (val) {
      if (val) {
        this.hls_time = this.video.hls_time || 10
        this.log = ''
        this.error = ''
        this.processVideo = {}
        this.isLoading = false
        this.isSendToQueue = false
        this.showClose = false

        if(this.video.status === 'queue' || this.video.status === 'encrypting'){
          this.isSendToQueue = true
          this.checkStatusIs0 = true
          this.checkStatus(5000)
        }

      }
    }
  },
  computed: {
    disabledForm() {
      return this.hls_time < 10 || this.hls_time > 180 || !this.video || !this.video.id
    },
    status(){
      return this.processVideo.status || this.video.status
    }
  },
  methods: {
    submit() {
      if(this.isLoading || !this.video || !this.video.id){
        return;
      }
      this.isLoading = true
      axios.post('/api/v1/video-encrypt/' + this.video.id + '/encrypt',
        {
          hls_time: this.hls_time
        })
        .then(res => {
          this.$toastr.success(this.$t('lk.videoEncrypt.EncryptModal.sent_queue'))
          this.$emit('success')
          this.isSendToQueue = true
          this.checkStatus(5000)
          if(res.data.data){
            this.processVideo = res.data.data
          }
          if(this.processVideo.hls_time){
            this.hls_time = this.processVideo.hls_time
          }
        })
        .catch(e => {
          if (e.response.status === 422) {

          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    checkStatus (delay, callback) {
      if (this.checkLogTo) {
        clearTimeout(this.checkLogTo)
      }
      if(!delay){
        delay = 10
      }
      this.checkLogTo = setTimeout(() => {
        let logLines = this.log === '' ? 0 : this.log.split(/\n/).length
        axios.post('/api/v1/video-encrypt/' + this.video.id + '/status',
          {
            log: logLines
          })
          .then(res => {
            if(res.data.data){
              this.processVideo = res.data.data
            }
            let isLogEmpty = this.log === ''
            if(res.data.log){
              this.log += (this.log ? "\n" : '') + res.data.log
              setTimeout(() => {
                this.$refs.log.scrollIntoView(false)
              }, 50)
            }
            if(res.data.fullLog !== undefined){
              this.log = res.data.fullLog
              setTimeout(() => {
                this.$refs.log.scrollIntoView(false)
              }, 50)
            }
            let isEncrypting = this.processVideo.status === 'encrypting' || this.processVideo.status === 'queue'
            if(this.show && isEncrypting && delay){
              if(isLogEmpty && this.log !== ''){
                delay = Math.floor(delay/2)
              }
              this.checkStatus(delay)
            }
            if(!isEncrypting){
              this.showClose = true
              if(this.isSendToQueue){
                this.$emit('success')
              }
            }
            this.error = this.processVideo.error || ''
            if(callback){
              callback()
            }
          })
          .catch(e => {})
      }, this.checkStatusIs0 ? 0 : delay)
      this.checkStatusIs0 = false
    },
    showLog() {
      this.isLoading = true
      this.checkStatus(0, () => {
        this.isSendToQueue = true
        this.isLoading = false
        this.showClose = true
      })
    },
    close() {
      if (this.checkLogTo) {
        clearTimeout(this.checkLogTo)
      }
      this.hls_time = 10
      this.log = ''
      this.error = ''
      this.processVideo = {}
      this.isLoading = false
      this.isSendToQueue = false
      this.showClose = false
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.EncryptModal{

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;
    font-size: 21px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 0 32px;
      padding-top: 24px;
    }

    &-close {
      font-size: 46px;
      font-weight: 100;
      line-height: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__log {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 20px;
    max-height: calc(100vh - 350px);
    max-height: calc(100dvh - 350px);
    overflow: auto;
    padding: 10px;
    pre{
      color: $primary-100;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
