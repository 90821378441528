<template>
  <CModal
    centered
    :closeOnBackdrop="false"
    :title="$t('lk.videoEncrypt.EditModal.title') + (video.title || '')"
    :show="show"
    @update:show="close"
    class="EditModal"
  >
    <CInput
      v-model="name"
      :label="$t('lk.videoEncrypt.EditModal.name')"
      :placeholder="$t('lk.videoEncrypt.EditModal.namePlaceholder')"
      type="text"
    />
    <CTextarea
      v-model="description"
      :label="$t('lk.videoEncrypt.EditModal.description')"
      :placeholder="$t('lk.videoEncrypt.EditModal.descriptionPlaceholder')"
    />
    <div class="EditModal__btnBlock">
      <CLoadingButtonCustom
        class="shadow-none EditModal__btn"
        color="success"
        :loading="isLoading"
        :disabled="isLoading || disabledForm"
        @click.native="submit"
      >
        {{$t('lk.videoEncrypt.EditModal.btnSubmit')}}
      </CLoadingButtonCustom>
      <CButton class="shadow-none EditModal__btn"
               color="primary"
               :disabled="isLoading"
               @click="close">
        {{$t('lk.videoEncrypt.EditModal.btnCancel')}}
      </CButton>
    </div>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoading: false,
      name: this.video.name || '',
      description: this.video.description || '',
    }
  },
  watch: {
    'show': function (val) {
      if (val) {
        this.name = this.video.name || ''
        this.description = this.video.description || ''
      }
    }
  },
  computed: {
    disabledForm() {
      return this.name === this.video.name
        && this.description === this.video.description
    }
  },
  methods: {
    submit() {
      if(this.isLoading || !this.video || !this.video.id){
        return;
      }
      this.isLoading = true
      axios.post('/api/v1/video-encrypt/' + this.video.id,
        {
          name: this.name,
          description: this.description
        })
        .then(res => {
          this.$toastr.success(this.$t('lk.videoEncrypt.EditModal.updated'))
          this.$emit('success')
          this.close()
        })
        .catch(e => {
          if (e.response.status === 422) {

          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    close() {
      this.name = ''
      this.description = ''
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.EditModal{

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;
    font-size: 21px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 0 32px;
      padding-top: 24px;
    }

    &-close {
      font-size: 46px;
      font-weight: 100;
      line-height: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
</style>
