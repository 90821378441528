<template>
  <div>
    <div class="header d-flex">
      <h1 class="title mb-0 mr-3">
        {{ $t(`lk.videoEncrypt.header`) }}
      </h1>
    </div>
    <CRow>
      <VideoEncryptTable>
      </VideoEncryptTable>
    </CRow>
  </div>
</template>

<script>
import VideoEncryptTable from "@/components/videoEncrypt/VideoEncryptTable";

export default {
  name: "PageVideoEncrypt",
  components: {
    VideoEncryptTable
  },
  data() {
    return {};
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 20px;
}
</style>
