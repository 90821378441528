var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "PlayerModal",
      attrs: {
        centered: "",
        size: "lg",
        closeOnBackdrop: true,
        show: _vm.show,
        title: _vm.title,
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.video.hash
        ? _c("VideoPlayer", {
            attrs: {
              video: _vm.video,
              height: 400,
              autoplay: true,
              controls: true,
            },
          })
        : _vm._e(),
      _c("br"),
      _c("CInput", {
        attrs: {
          disabled: "",
          value: _vm.video.playlist,
          label: _vm.$t("lk.videoEncrypt.PlayerModal.playlist"),
          type: "text",
        },
      }),
      _c("CInput", {
        attrs: {
          disabled: "",
          value: _vm.video.poster,
          label: _vm.$t("lk.videoEncrypt.PlayerModal.poster"),
          type: "text",
        },
      }),
      _c("CTextarea", {
        attrs: {
          disabled: "",
          value: _vm.video.hash,
          label: _vm.$t("lk.videoEncrypt.PlayerModal.hash"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }