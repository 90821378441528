var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "EncryptModal",
      attrs: {
        centered: "",
        closeOnBackdrop: false,
        size: "xl",
        title:
          _vm.$t("lk.videoEncrypt.EncryptModal.title") +
          (_vm.video.title || ""),
        show: _vm.show,
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CInput", {
        attrs: {
          disabled: _vm.isSendToQueue || _vm.isLoading,
          label: _vm.$t("lk.videoEncrypt.EncryptModal.hls_time"),
          type: "number",
          min: "10",
          max: "180",
        },
        model: {
          value: _vm.hls_time,
          callback: function ($$v) {
            _vm.hls_time = $$v
          },
          expression: "hls_time",
        },
      }),
      _c("p", [
        _vm._v("Статус: "),
        _vm.error
          ? _c("span", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.$t("lk.videoEncrypt.EncryptModal.error"))),
            ])
          : _vm.status
          ? _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(`lk.videoEncrypt.table.statuses['${_vm.status}']`)
                )
              ),
            ])
          : _vm._e(),
      ]),
      _vm.isSendToQueue
        ? _c("div", [
            _c("div", { staticClass: "EncryptModal__log" }, [
              _c("pre", {
                ref: "log",
                domProps: { textContent: _vm._s(_vm.log) },
              }),
            ]),
            _vm.error
              ? _c("pre", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.error)),
                ])
              : _vm._e(),
            _vm.showClose
              ? _c(
                  "div",
                  { staticClass: "EncryptModal__btnBlock" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none EncryptModal__btn",
                        attrs: { color: "primary", disabled: _vm.isLoading },
                        on: { click: _vm.close },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("lk.videoEncrypt.EncryptModal.btnClose")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.isSendToQueue
        ? _c(
            "div",
            { staticClass: "EncryptModal__btnBlock" },
            [
              _c(
                "CLoadingButtonCustom",
                {
                  staticClass: "shadow-none EncryptModal__btn",
                  attrs: {
                    color: "success",
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading || _vm.disabledForm,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lk.videoEncrypt.EncryptModal.btnSubmit")) +
                      " "
                  ),
                ]
              ),
              this.video.status === "encrypted" ||
              this.video.status === "encrypting" ||
              this.video.status === "queue"
                ? _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "shadow-none EncryptModal__btn",
                      attrs: {
                        color: "light",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.showLog.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lk.videoEncrypt.EncryptModal.btnStatus")
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "CButton",
                {
                  staticClass: "shadow-none EncryptModal__btn",
                  attrs: { color: "primary", disabled: _vm.isLoading },
                  on: { click: _vm.close },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lk.videoEncrypt.EncryptModal.btnCancel")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }