var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "EditModal",
      attrs: {
        centered: "",
        closeOnBackdrop: false,
        title:
          _vm.$t("lk.videoEncrypt.EditModal.title") + (_vm.video.title || ""),
        show: _vm.show,
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CInput", {
        attrs: {
          label: _vm.$t("lk.videoEncrypt.EditModal.name"),
          placeholder: _vm.$t("lk.videoEncrypt.EditModal.namePlaceholder"),
          type: "text",
        },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
      _c("CTextarea", {
        attrs: {
          label: _vm.$t("lk.videoEncrypt.EditModal.description"),
          placeholder: _vm.$t(
            "lk.videoEncrypt.EditModal.descriptionPlaceholder"
          ),
        },
        model: {
          value: _vm.description,
          callback: function ($$v) {
            _vm.description = $$v
          },
          expression: "description",
        },
      }),
      _c(
        "div",
        { staticClass: "EditModal__btnBlock" },
        [
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "shadow-none EditModal__btn",
              attrs: {
                color: "success",
                loading: _vm.isLoading,
                disabled: _vm.isLoading || _vm.disabledForm,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("lk.videoEncrypt.EditModal.btnSubmit")) +
                  " "
              ),
            ]
          ),
          _c(
            "CButton",
            {
              staticClass: "shadow-none EditModal__btn",
              attrs: { color: "primary", disabled: _vm.isLoading },
              on: { click: _vm.close },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("lk.videoEncrypt.EditModal.btnCancel")) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }