<template>
  <CModal
    centered
    :closeOnBackdrop="false"
    :title="$t('lk.videoEncrypt.UploadPosterModal.title') + (video.title || '')"
    :show="show"
    @update:show="close"
    class="UploadPosterModal"
  >
    <CInputFile
      :label="$t('lk.videoEncrypt.UploadPosterModal.file')"
      accept=".jpg, .png"
      @change="onChangeInputFile"
      ref="uploadposter"
    />
    <div class="UploadPosterModal__btnBlock">
      <CLoadingButtonCustom
        class="shadow-none UploadPosterModal__btn"
        color="success"
        :loading="isLoading"
        :disabled="!file || isLoading || disabledForm"
        @click.native="upload"
      >
        {{$t('lk.videoEncrypt.EditModal.btnSubmit')}}
      </CLoadingButtonCustom>
      <CButton class="shadow-none UploadPosterModal__btn"
               color="primary"
               :disabled="isLoading"
               @click="close">
        {{$t('lk.videoEncrypt.UploadPosterModal.btnCancel')}}
      </CButton>
    </div>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UploadPosterModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object
    },
    uri: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false,
      disabledForm: true,
      file: null
    }
  },
  methods: {
    onChangeInputFile(files) {
      if (files && files.length > 0) {
        this.file = files[0]
        this.disabledForm = false
      }else{
        this.file = null
        this.disabledForm = true
        this.$refs.uploadvideo.$el.getElementsByTagName('input')[0].value = null
      }
    },
    upload() {
      if(this.isLoading || !this.file || !this.video || !this.video.id){
        return
      }
      this.isLoading = true
      let formData = new FormData()
      formData.append('poster', this.file)
      axios.post((this.uri || axios.defaults.baseURL) + '/api/v1/video-encrypt/' + this.video.id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.$toastr.success(this.$t('lk.videoEncrypt.UploadPosterModal.uploaded'))
          this.$emit('uploaded')
          this.close()
        })
        .catch(e => {
          if (e.response.status === 422) {

          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    close() {
      this.file = null
      this.disabledForm = true
      this.$refs.uploadposter.$el.getElementsByTagName('input')[0].value = null
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.UploadPosterModal{

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;
    font-size: 21px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 0 32px;
      padding-top: 24px;
    }

    &-close {
      font-size: 46px;
      font-weight: 100;
      line-height: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
</style>
