<template>
  <div class="videoEncryptTable">
    <UploadVideoModal
      @close="showUploadVideoModal = false"
      :show="showUploadVideoModal"
      :uri="uri"
      @uploaded="getItems()"
    />
    <UploadPosterModal
      @close="showUploadPosterModal = false"
      :show="showUploadPosterModal"
      :video="selectedItem"
      uri=""
      @uploaded="getItems()"
    />
    <EditModal
      @close="showEditModal = false"
      :show="showEditModal"
      :video="selectedItem"
      @success="getItems()"
    />
    <EncryptModal
      @close="showEncryptModal = false"
      :show="showEncryptModal"
      :video="selectedItem"
      @success="getItems()"
    />
    <RemoveConfirmModal
      @close="showRemoveConfirm = false"
      :show="showRemoveConfirm"
      :video="selectedItem"
      @success="getItems()"
    />
    <PlayerModal
      @close="selectedItem = {}; showPlayerModal = false"
      :video="selectedItem"
      v-if="showPlayerModal"
      :show="showPlayerModal"
      :title="playerModalTitle"
    />
    <div class="videoEncryptTable__header">
      <CLoadingButtonCustom
        color="primary"
        @click.native="getItems()"
      >
        Reload
      </CLoadingButtonCustom>
      <CSpinner
        class="ml-3"
        v-if="loadingTable"
        color="primary"
      />
      <CLoadingButtonCustom
        class="ml-auto videoEncryptTable__header-btn"
        color="primary"
        @click.native="showUploadVideoModal = true"
        >{{$t('lk.videoEncrypt.addVideo')}}</CLoadingButtonCustom
      >
    </div>
    <CCard color="second-card-bg" class="shadow-none videoEncryptTable__card">
      <CCardBody class="h-100 d-flex flex-column">
        <div class="table-wrap">
          <table class="table mb-0 videoEncryptTable__table">
            <thead>
              <tr>
                <th>{{ $t('lk.videoEncrypt.table.poster') }}</th>
                <th>{{ $t('lk.videoEncrypt.table.status') }}</th>
                <th>{{ $t('lk.videoEncrypt.table.video') }}</th>
                <th></th>
              </tr>
            </thead>

            <tbody color="primary">
              <tr v-for="(item, i) in items" :key="i">
                <td @click="selectItem(item);showUploadPosterModal = true" class="poster">
                  <img v-if="item.poster" :src="item.poster">
                  <CIcon v-else name="cilImage" />
                </td>
                <td>
                  {{ $t(`lk.videoEncrypt.table.statuses['${item.status}']`) }}
                  <div v-if="item.types.length"> {{ item.types.join(', ') }}</div>
                </td>
                <td>
                  <p>{{ $t('lk.videoEncrypt.table.filename') }}: {{ item.filename }}</p>
                  <p>{{ $t('lk.videoEncrypt.table.name') }}: {{ item.name }}</p>
                  <p>{{ $t('lk.videoEncrypt.table.description') }}: {{ item.description }}</p>
                  <p>{{ $t('lk.videoEncrypt.table.hls_time') }}: {{ item.hls_time }}</p>
                </td>
                <td class="text-right text-nowrap">
                  <CButton
                    v-if="item.status === 'encrypted' && item.playlist && item.hash"
                    class="mr-1"
                    color="white"
                    :disabled="loadingTable"
                    @click="selectItem(item);playModal(item)"
                  >
                    <CIcon name="cil-media-play" />
                  </CButton>
                  <CButton
                    v-if="item.filename && item.status !== 'new'"
                    class="mr-1"
                    color="primary"
                    :disabled="loadingTable"
                    @click="selectItem(item);showEncryptModal = true"
                  >
                    <CIcon name="cib-lets-encrypt" />
                  </CButton>
                  <CButton
                    class="mr-1"
                    color="success"
                    :disabled="loadingTable"
                    @click="selectItem(item);showEditModal = true"
                  >
                    <CIcon name="cil-pencil" />
                  </CButton>
                  <CButton
                    color="danger"
                    :disabled="loadingTable"
                    @click="selectItem(item);showRemoveConfirm = true"
                  >
                    <CIcon name="cil-x" />
                  </CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios"
import UploadVideoModal from '@/components/videoEncrypt/UploadVideoModal'
import UploadPosterModal from '@/components/videoEncrypt/UploadPosterModal'
import EditModal from '@/components/videoEncrypt/EditModal'
import EncryptModal from '@/components/videoEncrypt/EncryptModal'
import PlayerModal from '@/components/videoEncrypt/PlayerModal'
import RemoveConfirmModal from '@/components/videoEncrypt/RemoveConfirmModal'

export default {
  name: "VideoEncryptTable",
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  components: {
    UploadVideoModal,
    UploadPosterModal,
    EditModal,
    EncryptModal,
    PlayerModal,
    RemoveConfirmModal
  },
  data() {
    return {
      loadingTable: false,
      showUploadVideoModal: false,
      showUploadPosterModal: false,
      showPlayerModal: false,
      showEditModal: false,
      showEncryptModal: false,
      showRemoveConfirm: false,
      items: [],
      playerModalTitle: '',
      selectedItem: {},
      uri: ''
    };
  },
  created() {
    this.getItems()
  },
  methods: {
    async getItems() {
      this.loadingTable = true;

      await axios.get('/api/v1/video-encrypt?uri=1').then(({data}) => {
        this.items = []
        this.uri = data.uri || ''
        data.data.forEach(el => {
          if(el.id){
            const types = el.types || []
            if(!types.length){
              types.push('private')
            }
            this.items.push({
              id: el.id,
              status: el.status,
              types: types,
              filename: el.filename,
              mime: el.mime || 'application/x-mpegURL',
              name: el.name || '',
              description: el.description || '',
              hls_time: el.hls_time || 10,
              title: el.name || el.filename || el.id,
              poster: el.poster || null,
              playlist: el.playlist || null,
              hash: el.hash || null
            })
          }
        })
      })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    selectItem(item) {
      this.selectedItem = item
    },

    playModal(item) {
      if(!item.playlist || !item.hash){
        return;
      }
      this.showPlayerModal = true
      this.playerModalTitle = item.title
    }
  },
};
</script>

<style lang="scss" scoped>
.ccard-body {
  overflow: hidden;
}

.table {
  position: relative;

  thead tr th {
    background: var(--second-card-bg);
    top: -1px;
    z-index: 1;
  }
}

.videoEncryptTable {
  width: 100%;

  .table-wrap {
    overflow: auto;
    width: 100%;
    flex-grow: 1;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid var(--subscribe-card-bg);
    color: #20a8d8;
    padding: 12px 24px;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    &-btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

     &__white {
      color: var(--white);
    }
  }

  &__card {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0;
  }

  .__active {
    transform: rotate(-90deg);
  }

  &__table {

    &__btn {
      display: inline-block;
      transform: rotate(90deg);
      font-stretch: ultra-condensed;
      line-height: 0px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: rgba(#2eb85c, 0.2);
      }
    }

    tbody td {
      vertical-align: middle;

      &:nth-child(1) {
        text-align: center;
        width: 100px;
      }
      &:nth-child(2) {
        width: 100px;
      }
      &:nth-child(4) {
        width: 160px;
      }

      p{
        margin: 0;
      }
    }
  }

  .modal-header {
    border-bottom: none;
  }
}
.poster img{
  max-width: 80px;
}

@keyframes showSubTableAnim {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
</style>
