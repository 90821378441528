<template>
  <CModal
    centered
    :closeOnBackdrop="true"
    :title="$t('lk.videoEncrypt.RemoveConfirmModal.title') + (video.title || '') + '?'"
    :show="show"
    @update:show="close"
  >
    <CInput
      v-model="videoId"
      :label="$t('lk.videoEncrypt.RemoveConfirmModal.fieldVideoId') + (video.id || '')"
      :placeholder="$t('lk.videoEncrypt.RemoveConfirmModal.fieldVideoId') + (video.id || '')"
      type="text"
    />

    <div class="RemoveConfirmModal__btnBlock">
      <CLoadingButtonCustom
        class="shadow-none RemoveConfirmModal__btn"
        color="success"
        :loading="isLoading"
        :disabled="isLoading || disabledForm"
        @click.native="remove"
      >
        {{$t('lk.videoEncrypt.RemoveConfirmModal.btnSubmit')}}
      </CLoadingButtonCustom>
      <CButton class="shadow-none RemoveConfirmModal__btn"
               color="primary"
               :disabled="isLoading"
               @click="close">
        {{$t('lk.videoEncrypt.RemoveConfirmModal.btnCancel')}}
      </CButton>
    </div>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RemoveConfirmModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoading: false,
      videoId: ''
    }
  },
  watch: {
    'show': function (val) {
      if (val) {
        this.videoId = ''
      }
    }
  },
  computed: {
    disabledForm() {
      return this.videoId != this.video.id
    }
  },
  methods: {
    removeVideoSavedTime(){
      const videosCurrentTime = JSON.parse(localStorage.getItem('videosCurrentTime'))
      if(videosCurrentTime){
        for(const item in videosCurrentTime){
          if(item.includes(this.video.id))delete videosCurrentTime[item]
        }
        localStorage.setItem('videosCurrentTime', JSON.stringify(videosCurrentTime))
      }
    },
    remove() {
      if(this.isLoading || !this.video || !this.video.id){
        return;
      }
      this.isLoading = true
      axios.delete('/api/v1/video-encrypt/' + this.video.id)
        .then(res => {
          this.$toastr.success(this.$t('lk.videoEncrypt.RemoveConfirmModal.deleted'))
          this.$emit('success')
          this.close()
          this.removeVideoSavedTime()
        })
        .catch(e => {
          if (e.response.status === 422) {

          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    close() {
      this.videoId = ''
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.RemoveConfirmModal{

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;
    font-size: 21px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 0 32px;
      padding-top: 24px;
    }

    &-close {
      font-size: 46px;
      font-weight: 100;
      line-height: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
</style>

</style>
